import { useEffect, useState, useContext } from "react";
import { get } from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ALL_PROJECT_DETAILS from "../../allProjects.json";
import { ethers } from "ethers";
import Web3Context from "../contexts/Web3Context";
import Loader from "../components/Loader";

function ProjectDetailsCard({ collection, contract, tokenId, chainId }) {
  return (
    <>
      <div className="card col-10 col-sm-8 col-md-3 m-2">
        <img className="img-fluid placeholder-img rounded-top" src="" alt="" />

        <div className="card-caption col-12 p-0 text-center">
          <div className="card-body">
            <div className="countdown-times mb-3">
              <div
                className="countdown d-flex justify-content-center"
                data-date={"date"}
              />
            </div>
            <a href={`/project-details/${chainId}/${contract.toUpperCase()}`}>
              <h5 className="mb-0">
                {collection} #{tokenId}
              </h5>
            </a>
            {/* {listed && (
                <button
                  type="button"
                  onClick={copyLink}
                  className="btn btn-info m-2"
                >
                  Copy Link
                </button>
              )}
              {!listed && !available && (
                <button
                  type="button"
                  onClick={Transfer}
                  className="btn btn-info m-2"
                >
                  Transfer
                </button>
              )}
              {listed && (
                <button
                  type="button"
                  onClick={delist}
                  className="btn btn-info m-2"
                >
                  Delist
                </button>
              )}
              {available && (
                <>
                  <h5 className="mt-2">
                    {ethers.utils.formatEther(price)} {symbol}
                  </h5>
                  <button type="button" onClick={buy} className="btn btn-info ">
                    Buy
                  </button>
                </>
              )} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default function MyNfts() {
  const { account, getMyNfts, signer, currentNetwork } =
    useContext(Web3Context);
  const [loading, setLoading] = useState(false);
  //   const { id } = useParams();
  //   const projectDetails = ALL_PROJECT_DETAILS[id];
  const [metadata, setMetaData] = useState([]);
  const { location } = window;
  useEffect(() => {
    const fechdata = async () => {
      try {
        setLoading(true);
        if (account && currentNetwork.CHAIN_ID) {
          let data = await getMyNfts();
          setMetaData(data);
        }
        setLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };
    fechdata();
  }, [account, signer, currentNetwork]);

  return (
    <section className=" content-minh">
      {loading && <Loader />}
      <div className="row g-5  justify-content-center mb-5">
        {!loading &&
          (!account ? (
            <span className="div-center" style={{ fontSize: "30px" }}>
              Connect wallet
            </span>
          ) : (
            metadata &&
            (metadata.length !== 0 ? (
              metadata.map((item, idx) => {
                return (
                  <ProjectDetailsCard
                    // nftContract={nftContract}
                    key={idx}
                    tokenId={item.tokenId}
                    collection={item.collection}
                    contract={item.contract}
                    chainId={currentNetwork.CHAIN_ID}
                  />
                );
              })
            ) : (
              <span className="div-center" style={{ fontSize: "30px" }}>
                No NFTs owned.
              </span>
            ))
          ))}
      </div>
    </section>
  );
}
