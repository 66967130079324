import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Web3Context from "../../contexts/Web3Context";
import ProjectDetailsCard from "./ProjectDetailsCard";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import Loader from "../../components/Loader";
import { ethers } from "ethers";
export default function ProjectDetailsList(props) {
  const {
    getAllUserTokens,
    account,
    getUserListing,
    availableForMe,
    withdrawBalance,
    balance,
    initContract,
    contractObjects,
  } = useContext(Web3Context);
  const [nftContract, setNftContract] = useState(false);
  const [contract, setContract] = useState(false);
  const [state, setState] = useState({
    nfts: [],
    userListing: [],
    availableTokens: [],
    price: [],
    tokenSymbol: [],
    tokenAddress: [],
  });
  const [loading, setLoading] = useState(false);
  const { chainId, address } = useParams();
  useEffect(() => {
    async function fecthdata() {
      try {
        setLoading(true);
        setState({
          ...state,
          nfts: await getAllUserTokens(address),
        });
      } catch {
      } finally {
        setLoading(false);
      }
    }
    if (account && ethers.utils.isAddress(address)) {
      setNftContract(initContract(address));
    }
    if (ethers.utils.isAddress(address)) setContract(address);
    else {
      setContract(address);
    }
  }, [account, address, contractObjects]);
  useEffect(() => {
    async function userListing() {
      try {
        setLoading(true);
        if (props.toggleStatus === "own") {
          let nfts;
          if (ethers.utils.isAddress(address)) {
            nfts = await getAllUserTokens(address);
          } else {
            nfts = await getAllUserTokens(address);
          }
          setState({ ...state, nfts });
        } else if (props.toggleStatus === "listed") {
          if (ethers.utils.isAddress(address)) {
            const data = await getUserListing(address);
            setState({ ...state, userListing: data[0] });
          } else {
            const data = await getUserListing(address);
            setState({ ...state, userListing: data[0] });
          }
        } else {
          if (ethers.utils.isAddress(address)) {
            if (props.toggleStatus === "only") {
              const data = await availableForMe(address, true);
              setState({
                ...state,
                availableTokens: data[0],
                price: data[1],
                tokenSymbol: data[2],
                tokenAddress: data[3],
              });
            } else {
              const data = await availableForMe(address);
              setState({
                ...state,
                availableTokens: data[0],
                price: data[1],
                tokenSymbol: data[2],
                tokenAddress: data[3],
              });
            }
          } else {
            if (props.toggleStatus === "only") {
              const data = await availableForMe(address, true);
              setState({
                ...state,
                availableTokens: data[0],
                price: data[1],
                tokenSymbol: data[2],
                tokenAddress: data[3],
              });
            } else {
              const data = await availableForMe(address);
              setState({
                ...state,
                availableTokens: data[0],
                price: data[1],
                tokenSymbol: data[2],
                tokenAddress: data[3],
              });
            }
          }
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
    if (account) {
      userListing();
    }
  }, [props.toggleStatus, account, contractObjects]);

  return (
    <>
      {loading && <Loader />}
      {/* <button
        onClick={() => {
          setState({ ...state, show: "own" });
        }}
      >
        My Nft
      </button>
      <button
        onClick={() => {
          setState({ ...state, show: "listed" });
        }}
      >
        Listed Tokens
      </button>
      <button
        onClick={() => {
          setState({ ...state, show: "available" });
        }}
      >
        Available
      </button>
      <button
        onClick={() => {
          withdrawBalance();
        }}
      >
        Withdraw balance
      </button>
      <button
        onClick={() => {
          balance();
        }}
      >
        Check balance
      </button> */}
      <div
        className="row g-5 justify-content-center mb-5"
        style={{ minHeight: "200px" }}
      >
        {props.toggleStatus == "own" &&
          !loading &&
          (state.nfts.length !== 0 ? (
            state.nfts.map((item, idx) => {
              return (
                <ProjectDetailsCard
                  nftContract={nftContract}
                  key={item}
                  token={item}
                  toggle={props.setToggle}
                  openModal={props.openModal}
                  closeModal={props.closeModal}
                />
              );
            })
          ) : (
            <span>No NFTs owned.</span>
          ))}
        {props.toggleStatus == "listed" &&
          !loading &&
          (state.userListing.length !== 0 ? (
            state.userListing.map((item, idx) => {
              return (
                <ProjectDetailsCard
                  nftContract={nftContract}
                  key={item}
                  token={item}
                  openModal={props.openModal}
                  toggle={props.setToggle}
                  closeModal={props.closeModal}
                  listed={true}
                  contract={contract}
                />
              );
            })
          ) : (
            <span>No NFTs listed.</span>
          ))}
        {(props.toggleStatus == "available" || props.toggleStatus == "only") &&
          !loading &&
          (state.availableTokens.length !== 0 ? (
            state.availableTokens.map((item, idx) => {
              return (
                <ProjectDetailsCard
                  nftContract={nftContract}
                  key={item}
                  token={item}
                  price={state.price[idx]}
                  symbol={state.tokenSymbol[idx]}
                  toggle={props.setToggle}
                  tokenAddress={state.tokenAddress[idx]}
                  openModal={props.openModal}
                  closeModal={props.closeModal}
                  available={true}
                />
              );
            })
          ) : (
            <span className="mt-5">No NFTs publicly available to buy.</span>
          ))}
      </div>
    </>
  );
}
