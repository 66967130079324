import { useEffect, useContext, useState } from "react";
import Modal from "react-modal";
import Web3Context from "../../contexts/Web3Context";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import ALL_TOKENS from "../../tokens.json";
import { useParams } from "react-router-dom";
import ShareLink from "react-twitter-share-link";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#16151a",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 3px 20px 0px rgba(0, 0, 0, .12)",
    width: "50vw",
  },
  overlay: {
    backgroundColor: "rgba(9, 8, 13, 0.6)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

export default function ModalPopup({
  token,
  img,
  contract,
  open,
  setToggle,
  projectName,
  closeModal,
}) {
  const { chainId, address } = useParams();
  const { generateLink, contractDetails, presentNetwork } =
    useContext(Web3Context);
  const [reserved, setReserved] = useState(false);
  const [custom, setCustom] = useState(false);

  const erc20Tokens =
    NETWORK_DETAILS[presentNetwork]?.["ERC20"] &&
    Object.keys(NETWORK_DETAILS[presentNetwork]?.["ERC20"]);

  const [tokenSymbol, setTokenSymbol] = useState(
    NETWORK_DETAILS[presentNetwork]?.NATIVE_CURRENCY?.symbol
  );
  const [tokenAddress, setTokenAddress] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [isCustom, setIsCustom] = useState(false);
  const [btnState, setBtnState] = useState("List NFT");
  const [toggleReserved, setToggleReserved] = useState(false);

  const [state, setState] = useState({
    receiver: "0x0000000000000000000000000000000000000000",
  });
  useEffect(() => {
    setState({
      receiver: "0x0000000000000000000000000000000000000000",
      link: false,
    });
  }, [token]);
  async function Generate(e) {
    e.preventDefault();
    console.log(state, isNaN(state.price));
    if (isNaN(state.price)) toast.error("Enter valid numeric price.");
    else {
      if (ethers.utils.isAddress(state.receiver)) {
        setBtnState("Listing...");
        const success = await generateLink(
          contract,
          token,
          state.price,
          state.receiver,
          tokenAddress,
          setBtnState
        );
        success && setState({ ...state, link: true });
        success ? setBtnState("Listed") : setBtnState("List NFT");
      } else {
        toast.error("Invalid Receiver Address");
      }
    }
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00000";
  }

  function handleToken(e) {
    const name = e.target.value;
    if (name === "Custom") {
      setCustom(true);
      setIsCustom(true);
      return;
    }
    setIsCustom(false);
    setTokenSymbol(name);
    if (name !== NETWORK_DETAILS[presentNetwork]?.NATIVE_CURRENCY.symbol) {
      setTokenAddress(NETWORK_DETAILS[presentNetwork]?.["ERC20"]?.contract);
    } else {
      setTokenAddress("0x0000000000000000000000000000000000000000");
    }
  }

  function handleCustomToken(e) {
    const address = e.target.value;
    setTokenAddress(address);
  }
  async function addCustomToken() {
    const res = await contractDetails(tokenAddress);
    setTokenSymbol(res.symbol);
    setCustom(false);
  }
  const preventMinus = (e) => {
    if (
      e.code === "Minus" ||
      e.code === "NumpadAdd" ||
      e.code === "NumpadSubtract"
    ) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const { location } = window;
  return (
    <div>
      <Modal isOpen={open} onAfterOpen={afterOpenModal} style={customStyles}>
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={props.closeModal}>close</button>
        <div>I am a modal</div> */}
        <div className="intro mt-0 mb-5 " style={{ textAlign: "center" }}>
          <div className="intro-content">
            <div style={{ textAlign: "end" }}>
              <img
                src="/assets/fonts/xmark-solid.svg"
                alt="closeIcon"
                width="15px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCustom(false);
                  setIsCustom(false);
                  setBtnState("List NFT");
                  setState({ ...state, link: false });
                  state.link && setToggle("listed");
                  closeModal();
                }}
              />
            </div>
            <h3 className="modal-title mb-4">Sell your NFT on Escrow</h3>
            <img
              className="img-fluid rounded-top"
              style={{ height: "150px" }}
              src={img}
              alt=""
            />
            <h4 className="mt-3 mb-0">{`${projectName}`}</h4>
          </div>
        </div>

        <form className="item-form modal-form">
          <div className="row justify-content-center">
            {/* <div className="col-12">
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Price"
                  required="required"
                />
              </div>
            </div> */}
            <div className={custom ? "col-12 col-md-10" : "col-12 col-md-6"}>
              <div className="form-group d-flex align-items-center gap-2">
                <input
                  type="number"
                  inputmode="numeric"
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                  className="form-control mr-3 price-input"
                  name="price"
                  placeholder="Item Price"
                  required="required"
                  value={state.price}
                  onChange={(e) => {
                    setState({ ...state, price: e.target.value });
                  }}
                />
                {custom ? (
                  <>
                    <input
                      type="text"
                      className="form-control mr-3"
                      name="tokenAddress"
                      placeholder="Token Address..."
                      required="required"
                      onChange={handleCustomToken}
                    />
                    <button
                      onClick={addCustomToken}
                      className="btn w-50"
                      type="button"
                    >
                      Add
                    </button>
                  </>
                ) : (
                  <select
                    className="coin-dropdown "
                    name="currency"
                    id="currency"
                    onChange={handleToken}
                  >
                    {isCustom && (
                      <option selected value={tokenSymbol}>
                        {tokenSymbol}
                      </option>
                    )}
                    <option value="NATIVE">
                      {NETWORK_DETAILS[presentNetwork]?.NATIVE_CURRENCY.symbol}
                    </option>
                    {erc20Tokens?.length !== 0 &&
                      erc20Tokens?.map((token, idx) => (
                        <option key={idx} value={token.symbol}>
                          {token.symbol}
                        </option>
                      ))}
                    {/* <option value="GSM">GSM</option>
                    <option value="XGSM">XGSM</option> */}
                    <option value="Custom">Custom</option>
                  </select>
                )}
              </div>
            </div>
            {/* <div className="col-12 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="copies"
                  placeholder="No of Copies"
                  required="required"
                />
              </div>
            </div> */}
            <div className="col-12">
              <div className="my-3 row justify-content-center">
                <div className=" text-center form-check-inline">
                  <span className="mr-3">Reserve</span>
                  <label class="switch">
                    <input
                      type="checkbox"
                      defaultChecked={reserved}
                      onChange={() => {
                        setReserved((prev) => !prev);
                        if (reserved)
                          setState({
                            ...state,
                            receiver:
                              "0x0000000000000000000000000000000000000000",
                          });
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                {/* <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    defaultChecked={reserved}
                  />
                  <label className="form-check-label" for="flexCheckChecked">
                    Reserve
                  </label>
                </div> */}
              </div>
            </div>
            {reserved && (
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control p-3"
                    name="Link"
                    style={{ color: "#fff" }}
                    placeholder="Reserve For"
                    required="required"
                    onChange={(e) =>
                      setState({ ...state, receiver: e.target.value })
                    }
                  />
                </div>
              </div>
            )}
            {/* <button
              className="btn w-40 "
              type="submit"
              style={{ fontSize: "0.85rem", padding: "13px" }}
              onClick={Generate}
            >
              Generate Link
            </button> */}
            {state.link && (
              <div className="owner d-flex justify-content-between align-items-center px-2">
                <span className="flex-wrap" style={{ fontSize: "0.8rem" }}>
                  {"Your Link: " +
                    `${location.protocol}//${location.host}/reserved/${chainId}/${contract}/${token}`}
                </span>
                <button
                  type="button"
                  className="mx-2 "
                  style={{
                    background: "rgb(22, 21, 26)",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={(e) => {
                    toast.success("Link Copied");
                    navigator.clipboard.writeText(
                      `${location.protocol}//${location.host}/reserved/${chainId}/${contract}/${token}`
                    );
                  }}
                >
                  <img src="/img/copy-icon.png" />
                </button>
                <ShareLink
                  link={`${location.protocol}//${location.host}/reserved/${chainId}/${contract}/${token}`}
                  text={`I just listed my ${projectName} ! Check it out here:`}
                  hashtags={["NFT", "Escrow", "EzScrow"]}
                  via={"EzToolsOfficial"}
                >
                  {(link) => (
                    <a href={link} target="_blank">
                      <svg
                        fill={"#ff66c4"}
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 26 22"
                      >
                        <path
                          fill="inherit"
                          d="M.5 18.7c10 6.2 22.7-.7 22.5-13 1-.7 1.9-1.6 2.6-2.7-1 .5-2 .8-3.1.9 1.14-.7 2-1.7 2.4-2.9-1 .6-2 1-3.3 1.3-4.3-4.13-10 .3-8.8 4.7-4.3-.2-8-2.3-10.6-5.4C1 4 1.5 7.1 3.9 8.5 3 8.4 2 8.2 1.5 7.8c0 2.6 1.8 4.6 4.1 5.1-.8.2-1.5.3-2.3.1.6 2 2.6 3.6 4.8 3.6-2 1.6-4.7 2.4-7.6 2.1z"
                        />
                      </svg>
                    </a>
                  )}
                </ShareLink>
              </div>
            )}
            <div className="col-12">
              <button
                onClick={btnState === "List NFT" && Generate}
                className="btn w-100 mt-3 mt-sm-4"
                type="submit"
              >
                {btnState}
              </button>
            </div>
            <div className="mt-2">*2% would be deducted as convenience fee</div>
          </div>
        </form>
      </Modal>
    </div>
  );
}
