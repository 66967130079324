import React from "react";

import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
// import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import ModalMenu from "../components/Modal/ModalMenu";
import Scrollup from "../components/Faq/Faq";
import Faq from "../components/Scrollup/Scrollup";
import { Explore } from "../section/home";
import HowItWorks from "../section/HowItWorks";
// import Faq from "../section/faq";
export default function Home() {
  return (
    <div className="main">
      {/* <Breadcrumb title="Explore" subpage="Explore" page="Explore Style 1" /> */}
      <Hero />
      <HowItWorks />
      <Explore />
      <Faq />
      <ModalSearch />
      <ModalMenu />
      <Scrollup />
    </div>
  );
}
