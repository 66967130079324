import { useEffect, useState, useContext } from "react";
import { get } from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import Web3Context from "../../contexts/Web3Context";
import { ethers } from "ethers";
export default function ProjectDetailsCard({
  openModal,
  token,
  listed,
  price,
  symbol,
  tokenAddress,
  available,
  nftContract,
  toggle,
  contract = false,
}) {
  const { deListToken, buyToken, contractDetails } = useContext(Web3Context);
  const { chainId, address } = useParams();
  const projectDetails = NETWORK_DETAILS[chainId]["ERC721"][address]?.details;

  const [metadata, setMetaData] = useState(false);
  const [decimal, setDecimal] = useState(18);
  const { location } = window;
  useEffect(() => {
    const fechdata = async () => {
      setMetaData({});
      try {
        if (nftContract) {
          let tokenuri = await nftContract.tokenURI(token);
          tokenuri = tokenuri.replace(
            "ipfs://",
            "https://puffs.mypinata.cloud/ipfs/"
          );
          const { data } = await get(tokenuri);
          setMetaData(data);
        } else {
          const { data } = await get(`${projectDetails?.dataUri}${token}`);
          setMetaData(data);
        }

        if (
          available &&
          tokenAddress !== "0x0000000000000000000000000000000000000000"
        ) {
          const tokenDetails = await contractDetails(tokenAddress);
          setDecimal(tokenDetails.decimals);
        }
      } catch (e) {
        setMetaData({});
        console.log(e.message);
      }
    };
    fechdata();
  }, []);

  async function copyLink(e) {
    e.preventDefault();
    // try {
    //   const { data } = await get(
    //     `${process.env.REACT_APP_BACKEND_URL}/getdetails/${contract}/${token}`
    //   );
    //   navigator.clipboard.writeText(
    //     `${location.protocol}//${location.host}/reserved/${data.result.linkId}`
    //   );
    // } catch (e) {
    //   console.log(nftContract.address);
    //   navigator.clipboard.writeText(
    //     `${location.protocol}//${location.host}/reserved/${contract}/${token}`
    //   );
    // }
    navigator.clipboard.writeText(
      `${location.protocol}//${location.host}/reserved/${chainId}/${contract}/${token}`
    );
    toast.success("Link Copied");
  }
  function Transfer() {
    if (nftContract) {
      let img = metadata?.image?.replace(
        "ipfs://",
        "https://puffs.mypinata.cloud/ipfs/"
      );
      openModal(token, img, nftContract.address, metadata.name, true);
    } else {
      let img = projectDetails.imageUri + token + projectDetails.imageExtension;
      openModal(
        token,
        img,
        projectDetails.contract,
        `${projectDetails.id} #${token}`
      );
    }
  }
  async function delist() {
    await deListToken(address, token);
    toggle("own");
  }
  async function buy() {
    await buyToken(address, token, price, tokenAddress);
    toggle("own");
  }
  return (
    <>
      {metadata && (
        <div className="card col-10 col-sm-8 col-md-3 m-2">
          {nftContract ? (
            <img
              className="projectListCard placeholder-img rounded-top"
              src={metadata?.image?.replace(
                "ipfs://",
                "https://puffs.mypinata.cloud/ipfs/"
              )}
              alt=""
            />
          ) : (
            <img
              className="projectListCard rounded-top placeholder-img"
              src={
                projectDetails.imageUri + token + projectDetails.imageExtension
              }
              alt=""
            />
          )}
          <div className="card-caption col-12 p-0 text-center">
            <div className="card-body">
              <div className="countdown-times mb-3">
                <div
                  className="countdown d-flex justify-content-center"
                  data-date={"date"}
                />
              </div>
              <a href="/item-details">
                <h5 className="mb-2">
                  {metadata.name ? metadata.name : `#${token}`}
                </h5>
              </a>
              {listed && (
                <button
                  type="button"
                  onClick={copyLink}
                  className="btn btn-info m-2"
                >
                  Copy Link
                </button>
              )}
              {!listed && !available && (
                <button
                  type="button"
                  onClick={Transfer}
                  className="btn btn-info m-2"
                >
                  List
                </button>
              )}
              {listed && (
                <button
                  type="button"
                  onClick={delist}
                  className="btn btn-info m-2"
                >
                  Delist
                </button>
              )}
              {available && (
                <>
                  <h5 className="mt-2">
                    {ethers.utils.formatUnits(price, decimal)} {symbol}
                  </h5>
                  <button type="button" onClick={buy} className="btn btn-info ">
                    Buy
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
