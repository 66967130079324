import { useEffect, useState, useContext } from "react";
import { get } from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ALL_PROJECT_DETAILS from "../../allProjects.json";
import { ethers } from "ethers";
import Web3Context from "../contexts/Web3Context";
import Loader from "../components/Loader";

export default function AddProject() {
  const { account, getMyNfts, signer, currentNetwork } =
    useContext(Web3Context);
  const [loading, setLoading] = useState(false);
  //   const { id } = useParams();
  //   const projectDetails = ALL_PROJECT_DETAILS[id];
  const [metadata, setMetaData] = useState([]);
  const { location } = window;
  useEffect(() => {
    const fechdata = async () => {
      try {
        setLoading(true);
        if (account && currentNetwork.CHAIN_ID) {
          let data = await getMyNfts();
          setMetaData(data);
        }
        setLoading(false);
      } catch (e) {
        console.log(e.message);
      }
    };
    fechdata();
  }, [account, signer, currentNetwork]);

  return (
    <section className=" content-minh">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfZa-TOqOJtpJGUE3z3gkybrrEV024bzSZnQYmUpmTiwbjcQg/viewform?embedded=true"
        className="w-100"
        style={{ height: "100vh" }}
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
      {/* <div className="text-center mt-4">Contact us for additional queries</div> */}
    </section>
  );
}
