import { useContext, useEffect, useState } from "react";
import Web3Context from "../contexts/Web3Context";
import ModalPopup from "../components/Modal/ModalPopup";
import { ethers } from "ethers";
import { ItemDetails, ProjectDetailsList } from "../section/projectDetails";
import NETWORK_DETAILS from "../contexts/networkDetails.json";
import Toggle from "react-toggle";
import { useParams } from "react-router-dom";
const supportedChains = Object.keys(NETWORK_DETAILS);
export default function ProjectDetails() {
  const { connectWallet, account, presentNetwork } = useContext(Web3Context);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { chainId, address } = useParams();
  const [wrongUrl, setWrongUrl] = useState(false);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [toggleStatus, setToggleStatus] = useState("own");
  // const [onlyForMe, setOnlyForMe] = useState(false);
  const [state, setState] = useState({});
  const openModal = (token, img, contract, projectName) => {
    setIsOpen(true);
    setState({ token, img, contract, projectName });
  };
  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (
      !supportedChains.find((e) => e === chainId) ||
      !ethers.utils.isAddress(address)
    ) {
      setWrongUrl(true);
    } else {
      setWrongUrl(false);
    }

    if (presentNetwork !== parseInt(chainId)) {
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
    }
  }, [presentNetwork]);

  return (
    <div className="content-minh">
      {wrongUrl ? (
        <div
          className="text-center d-flex flex-column align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <h3>Wrong URL!</h3>
          <h3>
            Go back{"  "}
            <a href="/" style={{ fontSize: "2rem" }}>
              <span>HOME</span>
            </a>
          </h3>
        </div>
      ) : wrongNetwork ? (
        <div
          className="text-center d-flex flex-column align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <h3>Wrong Network!</h3>
          <h3>
            Switch to <span>{NETWORK_DETAILS[chainId].name}</span>
          </h3>
        </div>
      ) : (
        <>
          <ItemDetails />

          <div className="row justify-content-center text-center">
            <div className="col-12">
              <div className="explore-menu tabs-responsive btn-group flex-wrap justify-content-center text-center mb-5">
                <button
                  className={
                    toggleStatus === "own"
                      ? "btn btn-border btn-responsive btn-large "
                      : "btn toggle btn-responsive btn-large "
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleStatus("own");
                  }}
                >
                  Owned
                </button>
                <button
                  className={
                    toggleStatus === "listed"
                      ? "btn btn-border btn-responsive btn-large "
                      : "btn toggle btn-responsive btn-large "
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleStatus("listed");
                  }}
                >
                  Listed
                </button>
                <button
                  className={
                    toggleStatus === "available" || toggleStatus === "only"
                      ? "btn btn-border btn-responsive btn-large "
                      : "btn toggle btn-large btn-responsive "
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleStatus("available");
                  }}
                >
                  Available
                </button>
              </div>
            </div>
            {(toggleStatus == "available" || toggleStatus == "only") && (
              <div className="mb-4 form-check-inline">
                <span className="mr-3">Only for me</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) setToggleStatus("only");
                      else setToggleStatus("available");
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            )}
          </div>
          {!account ? (
            <div className="text-center pb-5">
              <button
                className="btn btn-bordered-white btn-smaller mt-3"
                onClick={() => connectWallet()}
              >
                Connect Wallet
              </button>
            </div>
          ) : (
            <ProjectDetailsList
              open={modalIsOpen}
              toggleStatus={toggleStatus}
              setToggle={setToggleStatus}
              openModal={openModal}
              closeModal={closeModal}
            />
          )}
          <ModalPopup
            open={modalIsOpen}
            closeModal={closeModal}
            img={state.img}
            token={state.token}
            setToggle={setToggleStatus}
            projectName={state.projectName}
            contract={state.contract}
          />
        </>
      )}
    </div>
  );
}
