import React, { useState } from "react";
import ALL_PROJECTS from "../../allProjects.json";

const projectsData = Object.keys(ALL_PROJECTS)?.map((key) => ALL_PROJECTS[key]);

const initData = {
  menuName: "Search",
  menuIcon: "far fa-times-circle icon-close",
  heading: "What are you looking for?",
  content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  btnText: "Search",
};

export default function ModalSearch() {
  const [data] = useState(initData);
  const [projects] = useState(projectsData);

  return (
    <div id="search" className="modal fade p-0">
      <div className="modal-dialog dialog-animated">
        <div className="modal-content h-100">
          <div className="modal-header" data-dismiss="modal">
            {data.menuName} <i className={data.menuIcon} />
          </div>
          <div className="modal-body">
            <form className="row">
              <div className="col-12 align-self-center">
                <div className="row">
                  <div className="col-12 pb-3">
                    <h2 className="search-title mt-0 mb-3">{data.heading}</h2>
                    <p>{data.content}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 input-group mt-4">
                    <input type="text" placeholder="Enter your keywords" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 input-group align-self-center">
                    <a href="#explore">
                      <button
                        type="button"
                        className="btn btn-bordered-white mt-3"
                      >
                        {data.btnText}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
