import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "axios";
import { ethers } from "ethers";
import Web3Context from "../contexts/Web3Context";
import { toast } from "react-toastify";
import NETWORK_DETAILS from "../contexts/networkDetails.json";
import Loader from "../components/Loader";
import ModalNetwork from "../components/Modal/ModalNetwork";
const supportedChains = Object.keys(NETWORK_DETAILS);
export default function ReservedTokenContract() {
  const {
    listingInfo,
    account,
    buyToken,
    deListToken,
    presentNetwork,
    signer,
    contractObjects,
  } = useContext(Web3Context);
  const [loading, setLoading] = useState(false);
  const [tokenInfo, setTokenInfo] = useState({});
  const [wrongUrl, setWrongUrl] = useState(false);
  const [open, setOpen] = useState(false);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const { chainId, contract, tokenId } = useParams();
  const [action, setAction] = useState(null);
  // console.log("neywork", presentNetwork, parseInt(chainId));
  useEffect(() => {
    async function fetchToken() {
      console.count("Called Fetch Token");
      console.log(account, "lol");
      setLoading(true);
      let [listinginfo, tokendetails, tokenSymbol] = await listingInfo(
        contract,
        tokenId
      );
      // console.log(listinginfo, tokendetails);

      if (listinginfo || tokendetails) {
        setTokenInfo({
          ...tokenInfo,
          price: listinginfo[3],
          owner: listinginfo[0],
          tokenSymbol,
          payTokenAddress: listinginfo[5],
          reserved: listinginfo[1],
          name: tokendetails?.name,
          image: tokendetails?.image,
        });
      }

      setLoading(false);
    }
    if (account && presentNetwork) fetchToken();
  }, [account, action, presentNetwork, contractObjects]);

  useEffect(() => {
    if (
      !supportedChains.find((e) => e === chainId) ||
      !ethers.utils.isAddress(contract) ||
      isNaN(parseInt(tokenId))
    ) {
      setWrongUrl(true);
    } else {
      setWrongUrl(false);
    }

    if (presentNetwork !== parseInt(chainId)) {
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
    }
  }, [presentNetwork]);

  return (
    <section className="pb-5 content-minh">
      <ModalNetwork open={open} setOpen={setOpen} />
      {wrongUrl ? (
        <div
          className="text-center d-flex flex-column align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <h3>Wrong URL!</h3>
          <h3>
            Go back{"  "}
            <a href="/" style={{ fontSize: "2rem" }}>
              <span>HOME</span>
            </a>
          </h3>
        </div>
      ) : (
        wrongNetwork && (
          <div
            className="text-center d-flex flex-column align-items-center justify-content-center"
            style={{ height: "80vh" }}
          >
            <h3>Wrong Network!</h3>
            <h3>
              Switch to{" "}
              <span
                style={{ color: "#ff66c4", cursor: "pointer" }}
                onClick={() => setOpen(true)}
              >
                {NETWORK_DETAILS[chainId].name}
              </span>
            </h3>
          </div>
        )
      )}
      {}
      {!account ? (
        <div
          className="text-center d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <h3>Connect wallet to continue</h3>
        </div>
      ) : loading || !tokenInfo ? (
        <Loader />
      ) : (
        !wrongNetwork &&
        ((tokenInfo?.reserved?.toLowerCase() !== account &&
          tokenInfo?.reserved?.toLowerCase() !==
            "0x0000000000000000000000000000000000000000") ||
        tokenInfo.owner === "0x0000000000000000000000000000000000000000" ? (
          <div
            className="text-center d-flex align-items-center justify-content-center"
            style={{ height: "80vh" }}
          >
            <h3>
              {tokenInfo.owner === "0x0000000000000000000000000000000000000000"
                ? "Token delisted or purchased"
                : `This token is reserved for ${tokenInfo?.reserved}`}
            </h3>
          </div>
        ) : (
          <>
            <section className="item-details-area p-5 mb-2">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-12 col-lg-5 placeholder-img p-0">
                    {tokenInfo.image ? (
                      <img
                        className="img-fluid placeholder-img"
                        src={tokenInfo.image.replace(
                          "ipfs://",
                          "https://puffs.mypinata.cloud/ipfs/"
                        )}
                        alt=""
                      />
                    ) : (
                      <img
                        src="/img/auction-1.jpg"
                        className="img-fluid placeholder-img"
                        alt=""
                      />
                    )}
                    {/* <div className="item-info">
                    <div className="item-thumb text-center"></div>
                  </div> */}
                  </div>
                  <div className="col-12 col-lg-6">
                    {/* Content */}
                    <div className="content mt-5 mt-lg-0">
                      <h3 className="m-0">
                        {tokenInfo.name ? tokenInfo.name : `#${tokenId}`}
                      </h3>
                      <div className="owner my-2 d-flex align-items-center">
                        <span>Contract</span>

                        <h6 className="ml-4">
                          {" "}
                          {`${contract?.slice(0, 6)}...${contract?.slice(-4)}`}
                        </h6>
                        <button
                          className="mx-2 "
                          style={{ background: "#09080D", border: "none" }}
                          onClick={() => {
                            toast.success("Address Copied");
                            navigator.clipboard.writeText(contract);
                          }}
                        >
                          <img src="/img/copy-icon.png" />
                        </button>
                      </div>
                      {/* <span>Description</span> */}
                      {/* Owner */}
                      <div className="owner my-2 d-flex align-items-center">
                        <span>Owned By</span>

                        <h6 className="ml-4">{`${tokenInfo.owner?.slice(
                          0,
                          6
                        )}...${tokenInfo.owner?.slice(-4)}`}</h6>
                        <button
                          className="mx-2 "
                          style={{ background: "#09080D", border: "none" }}
                          onClick={() => {
                            toast.success("Address Copied");
                            navigator.clipboard.writeText(contract);
                          }}
                        >
                          <img src="/img/copy-icon.png" />
                        </button>
                      </div>
                      <div className="owner my-2 d-flex align-items-center">
                        <span>Price</span>
                        <h4 className="ml-4">
                          {ethers.utils.formatEther(tokenInfo.price)}{" "}
                          {tokenInfo.tokenSymbol}
                        </h4>
                      </div>

                      <div className="owner d-flex justify-content-start my-5 align-items-center">
                        {account === tokenInfo.owner.toLowerCase() ? (
                          <button
                            className="btn btn-large"
                            onClick={async () => {
                              await deListToken(contract, tokenId);
                              setAction("Delisted");
                            }}
                          >
                            {" "}
                            Delist Token
                          </button>
                        ) : (
                          tokenInfo.price && (
                            <button
                              className="btn btn-large"
                              onClick={async () => {
                                await buyToken(
                                  contract,
                                  tokenId,
                                  tokenInfo.price,
                                  tokenInfo.payTokenAddress
                                );
                                setAction("Bought");
                              }}
                            >
                              {" "}
                              Buy Token
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ))
      )}
    </section>
  );
}
