import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { get } from "axios";
import Web3Context from "../../contexts/Web3Context";
export default function ProjectCard({ item, contract, chainId }) {
  const { account, initContract } = useContext(Web3Context);
  const [state, setState] = useState({
    name: account ? "Loading..." : "Connect Wallet",
    coverImage: "",
    address: "",
    disabled: false,
  });

  async function initProject() {
    let nftContract, title;
    try {
      nftContract = await initContract(contract);
      title = await nftContract.name();
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        name: `Unable to load NFT. (Please ensure that the address is a valid ERC721 Contract) `,
        disabled: true,
        address: contract,
      });
    }

    if (title) {
      let uri, image;
      try {
        uri = await nftContract.tokenURI(1);
        uri = uri?.replace("ipfs://", "https://puffs.mypinata.cloud/ipfs/");
        const { data } = await get(uri);
        image = data?.image?.replace(
          "ipfs://",
          "https://puffs.mypinata.cloud/ipfs/"
        );
        // console.log(data);
      } catch (e) {
        console.log(e);
      }
      setState({
        ...state,
        name: title,
        coverImage: image,
        address: contract,
        disabled: false,
      });
    }
  }
  // console.log(state);
  useEffect(() => {
    if (item) {
      setState(item);
    } else if (contract && account) {
      setState({
        name: account ? "Loading..." : "Connect Wallet",
        coverImage: "",
        address: "",
      });
      initProject();
      console.log(contract, account);
    }
  }, [contract, account, item]);
  const { name: title, coverImage: img, address: id, disabled } = state;
  // console.log(img);
  return (
    <div className="col-12 col-lg-4 col-md-6 item ">
      <div className="card" id="exploreCard">
        <div className="image-over">
          <Link to={disabled ? "" : `/project-details/${chainId}/${id}`}>
            <img className="card-img-top" src={img} alt="" />
          </Link>
        </div>
        {/* Card Caption */}
        <div className="card-caption col-12 p-0">
          {/* Card Body */}
          <div className="card-body">
            <Link
              to={disabled ? "" : `/project-details/${chainId}/${id}`}
              style={{ textAlign: "center" }}
            >
              <h5 className="mb-0">{title}</h5>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
