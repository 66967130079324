import React, { Component, useState } from "react";
import ALL_PROJECTS from "../../allProjects.json";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
const projectsData = Object.keys(ALL_PROJECTS)?.map((key) => ALL_PROJECTS[key]);

export default function ExploreTwo(props) {
  const [exploreData, setExploreData] = useState(projectsData);
  const [searchVal, setSearchVal] = useState("");
  const handleChange = (event) => {
    setSearchVal(event.target.value);
  };

  const handleSearch = () => {
    setExploreData(() => {
      return projectsData.filter((item) =>
        item.title.toLowerCase().includes(searchVal.toLowerCase())
      );
    });
    setSearchVal("");
  };

  return (
    <section className="explore-area" id="explore">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Intro */}
            <div
              className="intro d-flex  justify-content-between align-items-end m-0"
              style={{ gap: "150px" }}
            >
              <div className="intro-content">
                <h3 className="mt-3 mb-0">
                  <span>Explore</span> Projects
                </h3>
              </div>
              <div className="d-flex  row  align-items-end">
                <div className="input-group">
                  <input
                    type="text"
                    value={searchVal}
                    onChange={handleChange}
                    style={{
                      height: "42.4px",
                      width: "400px",
                      borderStyle: "solid",
                    }}
                    placeholder="Enter collection or contract address"
                  />
                </div>

                <button
                  type="button"
                  onClick={handleSearch}
                  className="btn btn-smaller  btn-bordered-white ml-4"
                >
                  Search
                </button>

                {/* <div className="col-12 input-group align-self-center">
                  <a href="#explore">
                    <button
                      type="button"
                      className="btn btn-bordered-white mt-3"
                    ></button>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row items">
          {ethers.utils.isAddress(searchVal) ? (
            "lol"
          ) : exploreData?.length ? (
            exploreData.map((item, idx) => {
              return (
                <div
                  key={`edt_${idx}`}
                  className="col-12 col-lg-4 col-md-6 item"
                >
                  <div className="card" id="exploreCard">
                    <div className="image-over">
                      <Link to={`/project-details/${item.id}`}>
                        <img className="card-img-top" src={item.img} alt="" />
                      </Link>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                      {/* Card Body */}
                      <div className="card-body">
                        <Link
                          to={`/project-details/${item.id}`}
                          style={{ textAlign: "center" }}
                        >
                          <h5 className="mb-0">{item.title}</h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="m-auto text-center">
              <p className="mt-5" style={{ fontSize: "1.2rem" }}>
                No projects found.
              </p>
              <button
                type="button"
                onClick={handleSearch}
                className="btn btn-smaller  btn-bordered-white"
              >
                Explore All
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
