import { useEffect, useContext, useState } from "react";
import Modal from "react-modal";
import "./index.css";
import Web3Context from "../../../contexts/Web3Context";
import ALL_NETWORKS from "../../../contexts/networkDetails.json";
import NetworkCard from "./NetworkCard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#16151a",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 3px 20px 0px rgba(0, 0, 0, .12)",
    width: "70vw",
    zIndex: "50",
    paddingBottom: "2rem",
  },
  overlay: {
    backgroundColor: "rgba(9, 8, 13, 0.6)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const NetworksToShow = {
  "Mainnet Networks": Object.values(ALL_NETWORKS).filter(
    ({ testnet }) => !testnet
  ),
  "Testnet Networks": Object.values(ALL_NETWORKS).filter(
    ({ testnet }) => testnet
  ),
};
export default function ModalNetwork({
  token,
  img,
  contract,
  open,
  setOpen,
  projectName,
  closeModal,
}) {
  const { presentNetwork, promptChain, isWrongNetwork } =
    useContext(Web3Context);

  const requestClose = (e) => {
    if (isWrongNetwork) {
      e.preventDefault();
    } else {
      setOpen(false);
    }
  };
  const [isSwitchingNetwork, setIsSwitchingNetwork] = useState(false);
  return (
    <div>
      <Modal isOpen={open} style={customStyles} onRequestClose={requestClose}>
        <div className="intro mt-0 mb-5 " style={{ textAlign: "center" }}>
          <div style={{ textAlign: "end" }}>
            <img
              src="/assets/fonts/xmark-solid.svg"
              alt="closeIcon"
              width="15px"
              style={{ cursor: "pointer" }}
              onClick={requestClose}
            />
          </div>
          <div className="intro-content">
            <h3 className="modal-title mb-4">Select your network</h3>
          </div>
        </div>
        {isWrongNetwork && (
          <div class="oaerror danger">
            <div>
              <strong>Unsupported Network</strong>- Your current network (
              {presentNetwork}) is not supported.
              <br /> Please select from the list of supported networks below.
            </div>
          </div>
        )}
        {isSwitchingNetwork && (
          <div class="oaerror info">
            <div className="nb-spinner"></div>
            <div>
              <strong>Switching Network</strong> - Check your wallet.
            </div>
          </div>
        )}
        {Object.keys(NetworksToShow).map((networkType) => {
          const networks = NetworksToShow[networkType];
          return (
            <>
              <div className="sub-heading">{networkType}</div>
              <div className="network-items">
                {networks.map((e) => (
                  <NetworkCard
                    {...e}
                    isActive={e.CHAIN_ID == presentNetwork}
                    onClick={async (event) => {
                      if (isSwitchingNetwork) {
                        event.preventDefault();
                        return;
                      }
                      setIsSwitchingNetwork(true);
                      try {
                        promptChain(e.CHAIN_ID);
                      } catch (e) {
                        console.log(e);
                      }
                      setIsSwitchingNetwork(false);
                    }}
                  />
                ))}
              </div>
            </>
          );
        })}
      </Modal>
    </div>
  );
}
