import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import Web3Context from "../../contexts/Web3Context";
import { get } from "axios";
import axios from "axios";
export default function ItemDetails() {
  const { chainId, address } = useParams();
  const { connectWallet, account, initContract, signer } =
    useContext(Web3Context);
  const [project, setProject] = useState({
    name: account ? "NFT" : "Connect Wallet",
    address: address,
    image: "",
  });
  async function getProjectDetails() {
    // console.log(NETWORK_DETAILS[chainId]["ERC721"][address]);
    if (
      NETWORK_DETAILS[chainId] &&
      NETWORK_DETAILS[chainId]["ERC721"][address]
    ) {
      setProject(NETWORK_DETAILS[chainId]["ERC721"][address].details);
      return;
    }

    let contract = initContract(address);
    let title = await contract.name();
    let uri = await contract.tokenURI(1);
    uri = uri.replace("ipfs://", "https://puffs.mypinata.cloud/ipfs/");

    console.log(uri);
    const { data } = await get(uri);
    const image = data.image.replace(
      "ipfs://",
      "https://puffs.mypinata.cloud/ipfs/"
    );

    setProject({
      ...project,
      name: title,
      image: image,
    });
  }
  useEffect(() => {
    if (account && ethers.utils.isAddress(address)) {
      getProjectDetails();
    } else if (!ethers.utils.isAddress(address)) {
      setProject(ALL_PROJECT_DETAILS[address].details);
    }
  }, [account, signer]);

  return (
    <section className="pb-5">
      <div className="container text-center">
        <img src="/img/contour1.svg" alt="" />
        {/* <img src={project.coverImage} alt="" /> */}
        {project.image ? (
          <img className="project-img" src={project.image} alt="" />
        ) : (
          <img
            className="placeholder-img project-img"
            style={{ minHeight: "150px" }}
            alt=""
          />
        )}

        <div className="content ">
          <h3 className="mb-4">{project.name}</h3>
          {/* Owner */}

          <div className="owner d-flex justify-content-center align-items-center">
            <h5 className="ml-2">{`${project.address?.slice(
              0,
              6
            )}...${project.address?.slice(-6)}`}</h5>{" "}
            <button
              className="mx-2 "
              style={{ background: "#09080D", border: "none" }}
              onClick={() => {
                toast.success("Address Copied");
                navigator.clipboard.writeText(project.address);
              }}
            >
              <img src="/img/copy-icon.png" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
