import React from "react";

export default function HowItWorks() {
  return (
    <>
      <div className="divider"></div>
      <div className="container text-center mt-5" id="howItWorks">
        <h2>
          <span>How</span> it works ?
        </h2>

        <div className="howMaster">
          <div className="howBox d-flex justify-content-center align-items-center px-3">
            <p>List your NFT for a fixed native token or ERC20 amount</p>
          </div>
          <div className="arrow-long-right"></div>
          <div className="howBox d-flex justify-content-center align-items-center px-3">
            <p>Share your link with the buyer</p>
          </div>
          <div className="arrow-long-right"></div>
          <div className="howBox d-flex justify-content-center align-items-center px-3">
            <p>Buyer purchases by paying the ask</p>
          </div>
        </div>
      </div>
    </>
  );
}
