import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "axios";
import { ethers } from "ethers";
import Web3Context from "../contexts/Web3Context";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
export default function ReservedTokenLink() {
  const { listingInfo, account, buyToken, deListToken } =
    useContext(Web3Context);
  const [loading, setLoading] = useState(false);
  const [tokenInfo, setTokenInfo] = useState({});
  const { link } = useParams();
  useEffect(() => {
    async function fetchToken() {
      console.log(account, "lol");
      setLoading(true);
      let [listinginfo, tokendetails, tokenSymbol] = await listingInfo(
        tokenInfo.contract,
        tokenInfo.tokenId
      );
      // console.log("listinginfo", listinginfo, tokendetails);
      if (listinginfo)
        setTokenInfo({
          ...tokenInfo,
          price: listinginfo[3],
          owner: listinginfo[0],
          reserved: listinginfo[1],
          tokenSymbol,
          payTokenAddress: listinginfo[5],
          name: tokendetails.name,
          image: tokendetails.image,
        });
      tokenInfo && setLoading(false);
    }
    if (account && tokenInfo.contract && !tokenInfo.price) fetchToken();
  }, [account, tokenInfo.contract]);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await get(
          process.env.REACT_APP_BACKEND_URL + `/getdetails/${link}`
        );
        const { result } = data;
        setTokenInfo({
          ...tokenInfo,
          contract: result.contractAddresses,
          tokenId: result.tokenId,
        });
        console.log(result);
      } catch (e) {
        console.log(e.message);
      }
    }
    fetchData();
  }, []);

  return (
    <section className="pb-5 content-minh">
      {!account ? (
        <div
          className="text-center d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <h3>Connect wallet to continue</h3>
        </div>
      ) : loading || !tokenInfo ? (
        <Loader />
      ) : tokenInfo?.reserved?.toLowerCase() !== account &&
        tokenInfo?.reserved?.toLowerCase() !==
          "0x0000000000000000000000000000000000000000" ? (
        <div
          className="text-center d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <h3>
            {tokenInfo.owner
              ? "This token is reserved for "
              : "Token delisted or purchased"}{" "}
            {tokenInfo?.reserved}
          </h3>
        </div>
      ) : (
        <>
          <section className="item-details-area p-5 mb-2">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-5 placeholder-img">
                  {tokenInfo.image ? (
                    <img
                      className="img-fluid "
                      src={tokenInfo.image.replace(
                        "ipfs://",
                        "https://puffs.mypinata.cloud/ipfs/"
                      )}
                      alt=""
                    />
                  ) : (
                    <img
                      src="/img/auction-1.jpg"
                      className="img-fluid "
                      alt=""
                    />
                  )}
                </div>
                <div className="col-12 col-lg-6">
                  {/* Content */}
                  <div className="content mt-5 mt-lg-0">
                    <h3 className="m-0">{tokenInfo.name}</h3>
                    <div className="owner my-2 d-flex align-items-center">
                      <span>Contract</span>

                      <h6 className="ml-4">
                        {" "}
                        {`${tokenInfo.contract?.slice(
                          0,
                          6
                        )}...${tokenInfo.contract?.slice(-4)}`}
                      </h6>
                      <button
                        className="mx-2"
                        onClick={() => {
                          toast.success("Address Copied");
                          navigator.clipboard.writeText(tokenInfo.contract);
                        }}
                      >
                        <i className="fas fa-copy" />
                      </button>
                    </div>
                    {/* <span>Description</span> */}
                    {/* Owner */}
                    <div className="owner my-2 d-flex align-items-center">
                      <span>Owned By</span>

                      <h6 className="ml-4">{`${tokenInfo.owner?.slice(
                        0,
                        6
                      )}...${tokenInfo.owner?.slice(-4)}`}</h6>
                      <button
                        className="mx-2"
                        onClick={() => {
                          toast.success("Address Copied");
                          navigator.clipboard.writeText(tokenInfo.owner);
                        }}
                      >
                        <i className="fas fa-copy" />
                      </button>
                    </div>
                    <div className="owner my-2 d-flex align-items-center">
                      <span>Price</span>
                      <h4 className="ml-4">
                        {ethers.utils.formatEther(tokenInfo.price)}{" "}
                        {tokenInfo.tokenSymbol}
                      </h4>
                    </div>

                    <div className="owner d-flex justify-content-start my-5 align-items-center">
                      {account === tokenInfo.owner.toLowerCase() ? (
                        <button
                          className="btn btn-large"
                          onClick={() =>
                            deListToken(tokenInfo.contract, tokenInfo.tokenId)
                          }
                        >
                          {" "}
                          Delist Token
                        </button>
                      ) : (
                        tokenInfo.price && (
                          <button
                            className="btn btn-large"
                            onClick={() =>
                              buyToken(
                                tokenInfo.contract,
                                tokenInfo.tokenId,
                                tokenInfo.price,
                                tokenInfo.payTokenAddress
                              )
                            }
                          >
                            {" "}
                            Buy Token
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
}
// {/* <div className="container text-center">
//             <img src="/img/contour1.svg" alt="" />
//             {tokenInfo.image && (
//               <img
//                 className="project-img"
//                 src={tokenInfo.image.replace(
//                   "ipfs://",
//                   "https://puffs.mypinata.cloud/ipfs/"
//                 )}
//                 alt=""
//               />
//             )}
//             <div className="content ">
//               <h3 className="mb-4">{tokenInfo.name}</h3>
//               {/* Owner */}

//               <div className="owner d-flex justify-content-center align-items-center">
//                 <h6 className="ml-2">Contract address {tokenInfo.contract}</h6>{" "}
//                 <button
//                   className="my-2"
//                   onClick={() => {
//                     toast.success("Address Copied");
//                     navigator.clipboard.writeText(tokenInfo.contract);
//                   }}
//                 >
//                   <i className="fas fa-copy" />
//                 </button>
//               </div>
//               <div className="owner d-flex justify-content-center align-items-center">
//                 <h6 className="ml-2">Owner address {tokenInfo.owner}</h6>
//               </div>
//               <div className="owner d-flex justify-content-center align-items-center">
//                 <h6 className="ml-2">Reserved for {tokenInfo.reserved}</h6>
//               </div>
//             </div>
//           </div>
//           <div className="owner d-flex justify-content-center align-items-center">
//             {tokenInfo.price && (
//               <button
//                 className="btn btn-large"
//                 onClick={() =>
//                   buyToken(
//                     tokenInfo.contract,
//                     tokenInfo.tokenId,
//                     tokenInfo.price
//                   )
//                 }
//               >
//                 {" "}
//                 {ethers.utils.formatEther(tokenInfo.price)} buy Token
//               </button>
//             )}
//           </div> */}
