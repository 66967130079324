import React, { Component } from "react";

const initData = {
  pre_heading: "FAQ",
  heading: "Frequently Asked Questions",
};

const data = [
  {
    id: "1",
    btnClass: "btn d-block text-left w-100 py-4",
    target: "#collapseOne",
    quote: "What happens to the NFT after listing?",
    contentId: "collapseOne",
    contentClass: "collapse show",
    content:
      "Your NFT is securely stored in our contract to facilitate the exchange when buyer purchases the item",
  },
  {
    id: "2",
    btnClass: "btn d-block text-left w-100 collapsed py-4",
    target: "#collapseTwo",
    quote: "Can I reserve the buyer address?",
    contentId: "collapseTwo",
    contentClass: "collapse",
    content:
      "Yes, you can leave the reserved address blank to make it open purchase or enter a valid address to reserve it for a buyer",
  },
  {
    id: "3",
    btnClass: "btn d-block text-left w-100 collapsed py-4",
    target: "#collapseThree",
    quote: "Can I ask for payment in any ERC20 token?",
    contentId: "collapseThree",
    contentClass: "collapse",
    content: "Yes, but make sure the buyer can actually pay you in that token",
  },
  {
    id: "4",
    btnClass: "btn d-block text-left w-100 collapsed py-4",
    target: "#collapseFour",
    quote:
      "Does the project need to list their NFT for me to make a transaction?",
    contentId: "collapseFour",
    contentClass: "collapse",
    content:
      " No, all valid ERC721 tokens can be listed and traded on our platform without any listing process",
  },
  {
    id: "5",
    btnClass: "btn d-block text-left w-100 collapsed py-4",
    target: "#collapseFive",
    quote: "Do you have a fees?",
    contentId: "collapseFive",
    contentClass: "collapse",
    content: "Yes, we charge 2% in the token the payment is being made",
  },
];

class Faq extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: data,
    });
  }
  render() {
    return (
      <section className="faq-area pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              {/* Intro */}
              <div className="intro text-center">
                <span>{this.state.initData.pre_heading}</span>
                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              {/* FAQ Content */}
              <div className="faq-content">
                {/* Netstorm Accordion */}
                <div className="accordion" id="netstorm-accordion">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-10">
                      {/* Single Accordion Item */}
                      {this.state.data.map((item, idx) => {
                        return (
                          <div
                            key={`fd_${idx}`}
                            className="single-accordion-item p-3"
                          >
                            {/* Card Header */}
                            <div className="card-header bg-inherit border-0 p-0">
                              <h2 className="m-0">
                                <button
                                  className={item.btnClass}
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={item.target}
                                >
                                  {item.quote}
                                </button>
                              </h2>
                            </div>
                            <div
                              id={item.contentId}
                              className={item.contentClass}
                              data-parent="#netstorm-accordion"
                            >
                              {/* Card Body */}
                              <div className="card-body py-3">
                                {item.content}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
