// import logo from "./logo.svg";
// import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import MyRouts from "./routers/routes";
import { Web3Provider } from "./contexts/Web3Context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "font-awesome/css/font-awesome.min.css";
function App() {
  return (
    <div>
      <Web3Provider>
        <Header />
        <ToastContainer />
        <MyRouts />
        <Footer />
      </Web3Provider>
    </div>
  );
}

export default App;
