import { useContext, useState, useEffect } from "react";
import Web3Context from "../../contexts/Web3Context";
import ModalNetwork from "../Modal/ModalNetwork/index.js";
import ALL_NETWORKS from "../../contexts/networkDetails.json";
const supportedChains = Object.keys(ALL_NETWORKS);

const Header = () => {
  const {
    connectWallet,
    account,
    balance,
    withdrawBalance,
    currentNetwork,
    presentNetwork = "0",
    isWrongNetwork,
  } = useContext(Web3Context);
  const { icon: networkIcon = "/img/ghost.png" } =
    ALL_NETWORKS[presentNetwork] || {};
  const [state, setState] = useState({ balance: 0, symbol: "" });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!account) connectWallet();
    if (account) {
      if (isWrongNetwork) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [account, presentNetwork]);
  // async function withdraw(e) {
  //   e.preventDefault();
  //   await withdrawBalance();
  //   const [userBalance, symbol] = await balance(account);
  //   setState({ userBalance, symbol });
  // }
  return (
    <header id="header">
      <ModalNetwork open={open} setOpen={setOpen} />
      {/* Navbar */}
      <nav
        data-aos="zoom-out"
        data-aos-delay={800}
        className="navbar navbar-expand"
      >
        <div className="container header">
          {/* Navbar Brand*/}
          <a className="navbar-brand" href="/">
            <img
              className="navbar-brand-sticky"
              src="/img/logo.png"
              alt="sticky brand-logo"
            />
          </a>
          <div className="ml-auto" />
          {/* Navbar */}
          <ul className="navbar-nav items mx-auto">
            <li className="nav-item dropdown">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" href="#">
                Community <i className="fas fa-angle-down ml-1" />
              </a>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <a
                    target={"_blank"}
                    href="https://discord.gg/rPkYAvMhgW"
                    className="nav-link"
                  >
                    Discord
                  </a>
                </li>
              </ul>
            </li>
            {/* <li className="nav-item">
              <a href="/contact" className="nav-link">
                Contact
              </a>
            </li> */}
            {account && (
              <li className="nav-item">
                <a href="/mynfts" className="nav-link">
                  My NFTs
                </a>
              </li>
            )}
          </ul>
          {/* Navbar Toggler */}
          <ul className="navbar-nav toggle">
            <li className="nav-item">
              <a
                href="#"
                className="nav-link"
                data-toggle="modal"
                data-target="#menu"
              >
                <i className="fas fa-bars toggle-icon m-0" />
              </a>
            </li>
          </ul>
          {/* {account && (
            <ul className="navbar-nav action">
              <li className="nav-item ml-3">
                <a
                  onClick={withdraw}
                  className="btn ml-lg-auto btn-bordered-white"
                >
                  Withdraw{" "}
                  {state.userBalance &&
                    parseFloat(parseFloat(state.userBalance).toFixed(4)) +
                      " " +
                      state.symbol}
                </a>
              </li>
            </ul>
          )} */}
          {/* Navbar Action Button */}
          <ul className="navbar-nav action">
            <img
              onClick={() => setOpen(true)}
              src={networkIcon}
              alt=""
              className=""
              style={{ height: "3rem", cursor: "pointer" }}
            />
          </ul>
          {!account ? (
            <ul className="navbar-nav action">
              <li className="nav-item ml-3">
                <a
                  onClick={connectWallet}
                  className="btn ml-lg-auto btn-bordered-white"
                >
                  <i className="icon-wallet mr-md-2" />
                  Wallet Connect
                </a>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav action">
              <li className="nav-item ml-3">
                <a
                  // href="/wallet-connect"
                  className="btn ml-lg-auto btn-bordered-white"
                >
                  <i className="icon-wallet mr-md-2" />
                  {`${account?.slice(0, 6)}...${account?.slice(-4)}`}
                </a>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
