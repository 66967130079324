import React from "react";

function NetworkCard({ name, icon, chainId, isActive, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`network-item ${isActive ? "active" : ""}`}
    >
      <img className="icon" src={icon} alt="" />
      <div className="title">{name}</div>
    </div>
  );
}

export default NetworkCard;
