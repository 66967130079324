import React, { Component, useEffect, useState } from "react";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import ProjectCard from "./ProjectCard";
import { useContext } from "react";
import Web3Context from "../../contexts/Web3Context";

export default function ExploreTwo(props) {
  // return <></>;
  const { presentNetwork, isWrongNetwork, currentNetwork } =
    useContext(Web3Context);
  const [projectsData, setProjectsData] = useState([]);
  const [searchAdd, setSearchAdd] = useState(null);
  const [page, setPage] = useState(0);
  const [filteredProjectsData, setFilteredProjectsData] =
    useState(projectsData);
  const [searchVal, setSearchVal] = useState("");
  const handleChange = (event) => {
    setSearchVal(event.target.value);
  };

  const handleSearch = () => {
    if (ethers.utils.isAddress(searchVal)) {
      setSearchAdd(searchVal);
      setSearchVal("");
      setPage(0);
    } else {
      setFilteredProjectsData(() => {
        return projectsData.filter(({ details: { name = "" } }) =>
          name.toLowerCase().includes(searchVal.toLowerCase())
        );
      });

      setSearchVal("");
      setSearchAdd(null);
      setPage(0);
    }
  };
  useEffect(() => {
    if (isWrongNetwork) {
      setProjectsData([]);
      setFilteredProjectsData([]);
    } else {
      setProjectsData(
        Object.values(
          NETWORK_DETAILS[presentNetwork?.toString()]["ERC721"] || {}
        )
      );
      setFilteredProjectsData(
        Object.values(
          NETWORK_DETAILS[presentNetwork?.toString()]?.["ERC721"] || {}
        )
      );
    }
  }, [presentNetwork, currentNetwork, page]);
  // console.log(
  //   Object.values(
  //     NETWORK_DETAILS[presentNetwork?.toString()]["ERC721"].slice(0, 2)
  //   )
  // );
  // console.log(presentNetwork);
  return (
    <section className="explore-area" id="explore">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Intro */}
            <div className="intro d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-sm-center m-0">
              <div className="intro-content">
                <h3 className="mt-3 mb-4 mb-sm-0">
                  <span>Explore</span> Projects
                </h3>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-center align-items-sm-center">
                <div className="input-group">
                  <input
                    id="address-input"
                    type="text"
                    value={searchVal}
                    onChange={handleChange}
                    placeholder="Enter collection or contract address"
                  />
                </div>

                <button
                  type="button"
                  onClick={handleSearch}
                  className="btn btn-smaller  btn-bordered-white ml-4"
                >
                  Search
                </button>

                {/* <div className="col-12 input-group align-self-center">
                  <a href="#explore">
                    <button
                      type="button"
                      className="btn btn-bordered-white mt-3"
                    ></button>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row items">
          {searchAdd ? (
            <>
              <div className="m-auto text-center">
                <ProjectCard
                  contract={searchAdd}
                  chainId={currentNetwork.CHAIN_ID}
                />
                <button
                  type="button"
                  onClick={handleSearch}
                  className="btn btn-smaller mt-5 btn-bordered-white"
                >
                  Explore All
                </button>
              </div>
            </>
          ) : filteredProjectsData?.length ? (
            filteredProjectsData?.slice(0, page * 9 + 9).map((item, idx) => {
              return (
                <ProjectCard
                  key={idx}
                  chainId={presentNetwork}
                  item={item?.details}
                />
              );
            })
          ) : (
            <div className="m-auto text-center">
              <p className="mt-5" style={{ fontSize: "1.2rem" }}>
                No projects found.
              </p>
              <button
                type="button"
                onClick={handleSearch}
                className="btn btn-smaller mt-5 btn-bordered-white"
              >
                Explore All
              </button>
            </div>
          )}
        </div>
        {filteredProjectsData.length >
          filteredProjectsData.slice(0, page * 9 + 9).length &&
          filteredProjectsData.length !== 0 &&
          !searchAdd && (
            <div className="row">
              <div className="col-12 text-center">
                <a
                  id="load-btn"
                  className="btn btn-bordered-white mt-5"
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  Load more
                </a>
              </div>
            </div>
          )}
      </div>
    </section>
  );
}
