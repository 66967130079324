import React, { Component } from "react";
import axios from "axios";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json-2/footer";

class Footer extends Component {
  state = {
    data: {},
    socialData: [],
    widgetData_1: [],
    widgetData_2: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          socialData: res.data.socialData,
          widgetData_1: res.data.widgetData_1,
          widgetData_2: res.data.widgetData_2,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <footer className="footer-area">
        {/* Footer Top */}
        <div className="footer-top">
          <div className="container">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="col-12 col-sm-6 col-lg-3  res-margin">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Logo */}
                  <a className="navbar-brand" href="/">
                    <img src="/img/logo.png" alt="" />
                  </a>
                  <p>
                    Add value and cater to your project's needs to grow at an
                    unbelievably fast pace, with our ever expanding list of
                    products and services.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 res-margin">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h4 className="footer-title">Useful Links</h4>
                  <ul>
                    <li>
                      <a href="/addproject">
                        <span>Add</span> your project
                      </a>
                    </li>
                    <li>
                      <a href="https://ico.eztools.one/">
                        <span>Ez</span> Launch
                      </a>
                    </li>
                    <li>
                      <a href="https://airdrop.eztools.one/">
                        <span>Ez</span> drops
                      </a>
                    </li>
                    <li>
                      <a href="https://staking.eztools.one/">
                        <span>Ez</span> Staking
                      </a>
                    </li>
                    <li>
                      <a href="https://roles.eztools.one/VIPRavers/">
                        <span>Ez</span> Bots
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 res-margin">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h4 className="footer-title">Community</h4>
                  <ul>
                    <li>
                      <a href="https://discord.gg/rPkYAvMhgW">Discord</a>
                    </li>
                    <li>
                      <a href="https://twitter.com/EzToolsOfficial">Twitter</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Copyright Area */}
                <div className="copyright-area d-flex flex-wrap justify-content-center text-center py-4">
                  {/* Copyright Left */}
                  <div className="copyright-left">
                    ©2022 EzTools, All Rights Reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
